<template>
  <div class="box">

    <div v-for="(item, index) in list" :key="index" class="font-s-14 bgc" @click="goDetail(item)">

      <div class="flex a-i-fs jcsb ">
        <div class="flex">
          <div >
            <div>
              <span class="textcon" style=" font-size: 1.1rem;"> {{ item.cinemaName }}</span>
            </div>
            <div style="margin-top: 0.5rem; ">
              <span class="textcon2" style=" font-size: 0.85rem;"> {{ item.cinemaAddr }}</span>
            </div>
          </div>
        </div>
        <div class="buyvote">
          {{ getKm(item.gap) }}
        </div>
      </div>


      <!-- <div class="t-a-r p-b-10 msg">
        <div>创建时间：{{ item.add_time }}</div>
        <div class="p-tb-10">收货地址：{{ item.address }}</div>
        <div>
          共计{{ item.order_list.length }} 件商品 &nbsp; 合计：{{
            item.mallPrice
          }}
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    list: {
      type: Array,
    },
  },
  data() {
    return {};
  },
  components: {},
  methods: {
    getKm(gap) {
      if (!gap) {
        return ''
      }
      if (gap > 1000) {
        return '>1000km'
      } else {
        return gap + 'km'
      }
    },
    goDetail(val) {
      this.$router.push({
        path: "/CinemaDetail",
        query: {
          cinemaId: val.cinemaId,
          name: val.cinemaName,
          address: val.cinemaAddr,

        },
      });
    },
  },
  mounted() {
    // console.log(this.list);

  },
  computed: {},
  watch: {},
};
</script>

<style lang='scss' scoped>
.box {
  background-color: #f5f5f5;



  .bgc {
    background-color: #fff;
    border-radius: 0.5rem;
    margin: 0.57rem 1rem 0 1rem;
    padding: 1rem;
  }


}

.buyvote {

  width: 3.5rem;
  min-width: 3.5rem;
  text-align: end;
  font-size: 0.8rem;
  margin-top: 1rem;
  color: #D23B2E;
}

.textcon {

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.textcon2 {

overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;
color: #666;
}
</style>