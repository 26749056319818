<template>
  <div class="box">
    <div v-for="(item, index) in list" :key="index" class="font-s-14 bgc" @click="goDetail(item)">
      <div class="flex jcsb">
        <div style="display: flex;flex-direction: row;flex: auto;">
          <img class="img" :src="item.pic" />
          <div style="padding-left: 0.8rem;flex: auto;">
            <div style="align-items: center; display: flex;justify-content: space-between;">
              <span class="textname" style="font-weight: 600; font-size: 1rem;margin-top: 0.2rem;"> {{ item.name
              }}</span>
            </div>
            <div class="flex" style="font-size: 0.85rem;margin-top: 0.5rem;"><span style="color:#ffa976 ;">{{ item.like
            }}</span> <span style="color: #666;">人想看</span></div>
            <div style="margin-top: 0.5rem; height: 4rem;margin-right: 10px;">
              <span class="textcon" style=" font-size: 0.8rem;">{{ item.filmTypes }}</span>
              <span v-if="item.cast" class="textcon" style=" font-size: 0.8rem;margin-top: 0.5rem;">{{ item.director }}
                | {{ item.cast }}</span>

              <span v-else class="textcon" style=" font-size: 0.8rem;margin-top: 0.5rem;">{{ item.director }}</span>
            </div>

          </div>
          <div style="display: flex;justify-content: flex-end;">
            <div class="buyvote">
              想看
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    list: {
      type: Array,
    },
  },
  data() {
    return {};
  },
  components: {},
  methods: {
    goDetail(val) {
      this.$router.push({
        path: "/MovieDetail",
        query: {
          showbutton: 0,
          item: JSON.stringify(val),

        },
      });
    },
  },
  mounted() {
    // console.log(this.list);

  },
  computed: {},
  watch: {},
};
</script>

<style lang='scss' scoped>
.box {
  .img {
    width: 6rem;
    height: 8rem;
    min-width: 6rem;
    min-height: 7rem;
    border-radius: 0.2rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .bgc {
    background-color: #fff;
    border-radius: 0.5rem;
    margin: 0.57rem 1rem 0 1rem;
    padding: 1rem;
  }

  .msg {
    margin-top: 20px;
    padding-top: 5px;
    border-top: 1px solid rgb(184, 182, 182);
  }

}

.buyvote {
  background-color: #EE8A34;
  color: white;
  height: 1.5rem;
  line-height: 1.5rem;
  width: 4rem;
  margin-top: 3.5rem;
  min-width: 4rem;
  text-align: center;
  font-size: 0.8rem;
  border-radius: 1rem;
  margin-right: -0.5rem;
}

.fen {
  background-color: #131313;
  width: 100%;
  height: 1.5rem;
  min-height: 1.5rem;
  padding-top: 0.2rem;
  text-align: center;
  opacity: 0.7;
  font-size: 0.8rem;
  border-radius: 0.5rem;
}

.textname {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.top {
  border-top-left-radius: 0.6rem;
  border-bottom-left-radius: 0.6rem;
  background-color: #FFB83E;
  color: #FFF;
  font-size: 0.8rem;
  height: 1.2rem;
  width: 3rem;
  text-align: center;
  padding-top: 0.2rem;

}

.textcon {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: #666;
  margin-top: 0.3rem;
  font-size: 0.85rem;
}
</style>