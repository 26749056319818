<template>
  <div class="height-100">
    <loading v-if="loading2"></loading>
    <!-- 导航 -->
    <div v-else>
      <van-tabs v-model="active" sticky title-active-color="#D33B2E" title-inactive-color="#000" line-width="1.5rem">
        <van-tab title="热映">
          <div v-if="moviedata.length > 0">
            <div>
              <div style="display: flex;flex-direction: row;background-color: #EDEDED;">
                <div class="tagmore" @click="MoviesList()">全部热映</div>
                <carousel-3d :perspective="10" width="120" height="180" :space="140" :controlsHeight="80"
                  :on-slide-change="onSlideChanged">
                  <slide v-for="(item, index) in moviedata" :index="index" :key="index">
                    <div style="padding: 5%; width: 100%;height: 100%;">
                      <div :class="index == headIndex ? 'img2' : 'img'"
                        :style="{ backgroundImage: 'url(' + item.pic + ')', backgroundSize: '100% 100%' }">
                        <div class="fen">
                          <span v-if="item.grade" style="color: white;margin-left: 0.57rem;"> 评分 {{ item.grade }}</span>
                          <span v-else style="color: white;margin-left: 0.57rem;"> 暂无评分</span>
                        </div>
                      </div>
                      <div :class="index == headIndex ? 'textnamered' : 'textname'">{{ item.name }}</div>
                    </div>
                  </slide>
                </carousel-3d>
              </div>
              <cinema-time-item style="margin-top: 0.8rem;" :list="cinemasList"></cinema-time-item>
            </div>
          </div>
          <div class="empt" v-else></div>
        </van-tab>
        <van-tab title="影院">
          <div v-if="Cinemas.length > 0">
            <div style="background-color: white; padding: 0 1.2rem; border-top: 1px solid #DDD;">
              <location-select :type="1" @areaCinemas="areaCinemas"></location-select>
            </div>
            <van-list v-if="Cinemas.length > 0" v-model="loading" :finished="finished" finished-text="没有更多了"
              @load="onLoad">
              <cinema-item :list="Cinemas">
              </cinema-item>
            </van-list>
          </div>
          <div class="empt" v-else></div>
        </van-tab>
        <van-tab title="待映">
          <div v-if="waitData.length > 0">
            <!-- <van-swipe @change="onWaitChange" :autoplay="5000" indicator-color="white"
              style="height: 8rem;border-radius: 0.5rem; margin: 0.57rem 1rem 0.5rem 1rem;">
              <van-swipe-item v-for="(item, index) in waitHeadData" :key="index">
                <van-image fit="fill" :src="item.imgs[0]" alt="" class="imgswip" />
              </van-swipe-item>
              <template #indicator>
                <ul class="indicators">
                  <li class="indicator" v-for="(item, index) in headData" :key="index"
                    :class="{ active: checkWaitActive(index) }">
                  </li>
                </ul>
              </template>
            </van-swipe> -->
            <wait-item :list="waitData">
            </wait-item>
          </div>
          <div class="empt" v-else></div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import WaitItem from "../../components/movie/WaitItem.vue";
import CinemaItem from "../../components/movie/CinemaItem.vue";
import LocationSelect from "../../components/movie/LocationSelect.vue";
import CinemaTimeItem from "../../components/movie/CinemaTimeItem.vue";
import { Carousel3d, Slide } from 'vue-carousel-3d'

export default {
  name: "",
  props: {},
  data() {
    return {
      current: 0,
      waitCurrent: 0,
      boo: false,
      isLoading: false,
      loading: false,
      loading2: true,
      finished: false,
      moviedata: [],
      Cinemas: [],
      headData: [],
      hotMovie: {
        cardId: '',
        cityCode: '',
        current: 1,
        size: 10,
      },
      hotCiname: {
        cardId: '',
        cityCode: '',
        current: 1,
        size: 10,
        regionId: '',
      },
      cinemasList: [],
      waitData: [],
      waitHeadData: [],
      active: 1,
      headIndex: 0,
      latitude: '',
      longitude: ''

    };
  },
  components: { WaitItem, Carousel3d, Slide, CinemaItem, CinemaTimeItem, LocationSelect },
  methods: {
    MoviesList() {
      this.$router.push({
        path: "/MoviesList",
        query: {
          filmName: ''
        }
      });
    },
    areaCinemas(val) {
      this.hotCiname.regionId = val
      this.hotCiname.current = 1
      this.queryCinemas()
    },
    // 刷新
    onRefresh() {
      this.hotCiname.current = 1
      this.queryCinemas()
    },
    onLoad() {
      this.hotCiname.current = this.hotCiname.current + 1
      this.queryCinemas()
    },
    //返回
    goback() {
      this.$router.back();
    },
    // 获取热映
    queryHotMoviesByCity() {
      this.$api
        .queryHotMoviesByCity(this.hotMovie.cardId, this.hotMovie.cityCode, '')
        .then((res) => {
          if (res.code == 1) {
            this.$Toast(res.msg);
            this.loading2 = false
            return
          }
          if (res.data.length > 2) {
            this.headData = res.data.slice(0, 3)
          } else {
            this.headData = res.data
          }
          this.moviedata = res.data
          if (this.moviedata.length > 0) {
            this.queryFutureFilmsByFilmId(this.moviedata[0].filmId)
          }
        })
        .catch((err) => {
          this.loading2 = false
        });
    },
    //
    queryFutureFilmsByFilmId(id) {
      const form = {
        cardId: this.hotMovie.cardId,
        cityCode: this.hotMovie.cityCode,
        current: 1,
        filmId: id,
        size: 500,
        lat: this.latitude,
        lng: this.longitude
      }
      this.$api
        .queryFutureFilmsByFilmId(form)
        .then((res) => {
          if (res.code == 1) {
            this.$Toast(res.msg);
            this.loading2 = false
            return
          }
          this.cinemasList = res.data.futureFilms;
          this.loading2 = false
        })
        .catch((err) => {
          this.loading2 = false
        });
    },

    //影院
    queryCinemas() {
      this.hotCiname.cardId = this.hotMovie.cardId
      this.hotCiname.lat = this.latitude
      this.hotCiname.lng = this.longitude
      this.$api
        .queryCinemas(this.hotCiname)
        .then((res) => {
          if (res.code == 1) {
            this.$Toast(res.msg);
            this.loading2 = false
            return
          }
          if (this.hotCiname.current == 1) {
            this.Cinemas = res.data.records
          } else {
            this.Cinemas.push(...res.data.records)
          }
          if (res.data.records.length < 10) {
            this.finished = true
          }
          this.loading = false
          this.isLoading = false
        })
        .catch((err) => {
          this.finished = true
          this.loading = false
          this.isLoading = false
          // console.log(err);
        });
    },
    // 获取待映
    queryWaitShowMovie() {
      this.$api
        .queryWaitShowMovie()
        .then((res) => {
          if (res.code == 1) {
            this.$Toast(res.msg);
            this.loading2 = false
            return
          }
          if (res.data.length > 2) {
            this.waitHeadData = res.data.slice(0, 3)
          } else {
            this.waitHeadData = res.data
          }
          this.waitData = res.data

        })
        .catch((err) => {
          this.$toast(err)
        });
    },
    onSlideChanged(index) {
      this.headIndex = index
      this.cinemasList = []
      this.queryFutureFilmsByFilmId(this.moviedata[index].filmId)
    },
    checkActive(index) {
      return index === this.current;// current为监听的轮播index
    },
    onChange(index) {
      this.current = index;
    },
    onWaitChange(index) {
      this.waitCurrent = index;
    },
    checkWaitActive(index) {
      return index === this.waitCurrent;// current为监听的轮播index
    },
  },
  // inject: ["reload"],
  // beforeRouteEnter(to, from, next) {
  //   if (from.path !== "/CinemasList" && from.path !== "/SelectAdderss") {
  //     next((vm) => {
  //       vm.reload();
  //     });
  //   } else {
  //     next();
  //   }
  // },
  activated() {
  },

  //详情页获取参数
  mounted() {

    this.hotMovie.cardId = localStorage.getItem("cardId");
    this.hotMovie.cityCode = localStorage.getItem("cityCode");
    this.hotCiname.cityCode = localStorage.getItem('cityCode')
    this.latitude = localStorage.getItem('latitude')
    this.longitude = localStorage.getItem('longitude')
    this.queryHotMoviesByCity();
    this.queryCinemas()
    this.queryWaitShowMovie()
    this.active = Number(this.$route.query.active) ? Number(this.$route.query.active) : 0

  },
  computed: {},
  watch: {},
};
</script>

<style lang='scss' scoped>
.empt {
  height: 100px;
  text-align: center;
  line-height: 100px;
  font-size: 16px;
}

.title {
  font-weight: 600;
}

.imgswip {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: black;
}

.img {
  width: 90%;
  height: 80%;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.indicators {
  position: absolute;
  bottom: 5px;
  left: 50%;
  display: flex;
  transform: translateX(-50%);

  .indicator {
    width: 0.5rem;
    height: 0.5rem;
    background-color: #fff;
    border-radius: 50%;
    opacity: 0.6;
    transition: opacity 0.5s,
      background-color 0.5s;

    &:not(:last-child) {
      margin-right: 4px;
    }

    &:last-child {
      margin-right: 4px;
    }

    &.active {
      width: 1.2rem;
      height: 0.5rem;
      border-radius: 0.25rem;
      opacity: 1;
    }
  }
}

.img2 {
  width: 90%;
  height: 80%;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

}

.textname {
  margin-top: 0.5rem;
  width: 90%;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.textnamered {
  margin-top: 0.5rem;
  width: 90%;
  text-align: center;
  overflow: hidden;
  color: #D33B2E;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
   
}

.tagmore {
  width: 1.6rem;
  padding: 0.2rem;
  background-color: #D33B2E;
  font-size: 0.85rem;
  color: white;
  text-align: center;
  height: 7rem;
  min-height: 7rem;
  font-weight: 400;
  margin-top: 2.5rem;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.fen {
  background-color: #131313;
  width: 100%;
  height: 1.3rem;
  min-height: 1.3rem;
  padding-top: 0.2rem;

  opacity: 0.7;
  font-size: 0.8rem;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

::v-deep .van-tab {
  font-weight: 700;
}

::v-deep .carousel-3d-slide {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  top: 0;
  border-color: #ffffff00;
  border-style: solid;
  background-size: cover;
  background-color: #ffffff00;
  display: block;
  margin: 0;
  box-sizing: border-box;
  text-align: left;
}
</style>